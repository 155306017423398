import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menPath: '0'
  },
  mutations: {
    updateMenPath(state, menPath) {
      state.menPath = menPath;
    }
  },
  actions: {},
  modules: {}
})
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    icon:'el-icon-s-platform',
    menuName: '首页',
    component: () => import('../views/pages/home.vue')
  },{
    path: '/doc',
    name: 'Doc',
    icon:'el-icon-s-open',
    menuName: '教程/问题',
    component: () => import('../views/pages/doc.vue')
  },{
    path: '/treaty',
    name: 'Treaty',
    icon:'el-icon-s-management',
    menuName: '协议声明',
    component: () => import('../views/pages/treaty.vue')
  },{
    path: '/update',
    name: 'Update',
    icon:'el-icon-warning',
    menuName: '更新说明',
    component: () => import('../views/pages/update.vue')
  }
]


const router = new VueRouter({
  routes
})

export default router
